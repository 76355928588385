span.flag {
  width: 44px;
  height: 30px;
  display: inline-block;
}

img.flag {
  width: 30px;
}

.flag {
  background: url("https://primefaces.org/cdn/primeng/images/flag/flags_responsive.png") no-repeat;
  background-size: 100%;
  vertical-align: middle;
}

.flag-IT {
  background-position: 0 42.975207%;
}

.flag-EN {
  background-position: 0 92.561983%;
}
