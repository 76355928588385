/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/primeng.css";
@import "./assets/themes/flags.css";
@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";

:root {
  --primary-color: #fbbc6a;
  --danger-color: #fca5a5;
}

/* Typographic tweaks */
body {
  font-family:
    Segoe UI,
    Arial,
    sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  background-color: var(--surface-0);
}

/* Use better box-sizing model */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
}

/* Improve media defaults */
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/* Remove built-in form typography styles */
input,
button,
textarea,
select {
  font: inherit;
}

/* Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
// Ideally it should be 1024 ma va in conflitto con primeng ZIOPERA
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1580px) {
  .container {
    max-width: 1580px;
  }
}

.text-black {
  color: black !important;
}

.p-inputtext {
  padding: 0.5rem 0.5rem;
  height: 2.5rem;
}

.p-dropdown.cart-qty .p-inputtext {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 2rem;
  width: 2rem;
}

.p-badge {
  font-size: 0.7rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
}

.p-fileupload-buttonbar {
  flex-wrap: wrap;
  button,
  span {
    margin-bottom: 0.25rem;
  }
}

.p-fileupload-buttonbar .p-button-label {
  width: 75px;
}

.b2b-header {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.categories-button {
  height: 2.5rem;
  width: max-content;
  border-radius: 0;

  button {
    border-color: var(--surface-border);
    color: var(--surface-600);
  }
}

.categories-button .p-button-label {
  font-weight: 400;
  color: var(--surface-600);
}

.categories-button .p-splitbutton-menubutton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: var(--surface-border);
  color: var(--surface-600);
}

.grid {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}

.b2b-breadcrumb {
  background-color: unset;
  border: 0;
  padding: 1rem 0;
  font-size: 12px;
}

.b2b-breadcrumb .p-menuitem-separator {
  width: 12px;
}

.spacer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-general {
  width: 100%;
  border-radius: 4px;
  letter-spacing: 0.2rem;
  font-weight: 600;
  display: block;
  cursor: pointer;
}

.login-btn {
  border: #b96a02;
  color: #000;
  background-color: #fbbc6a;
  margin-top: 20px;
}

.form-error {
  text-align: center;
  color: red;
  margin-bottom: 15px;
}

/* Tab menu styling */
.p-menuitem-link {
  background-color: var(--surface-section);
  border-radius: 0;
  color: var(--surface-600);
}

.p-menuitem-link:hover {
  color: var(--blue-500);
}

/* verificare che non facciano casino */
.p-rating-icon {
  color: var(--yellow-500);
}

.stelle-rating-dettaglio {
  .p-rating-icon {
    color: var(--yellow-500);
    cursor: auto;
  }
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
  color: var(--orange-700);
}

.p-dataview .p-dataview-header {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.p-toolbar {
  background-color: rgb(240, 116, 116);
  font-weight: 600;
}

.border-l {
  border-width: 0 0 0 1px !important;
}

.p-breadcrumb .p-breadcrumb-list {
  gap: 0.5rem;
}

@media (max-width: 425px) {
  .p-paginator-rpp-options {
    margin-top: 0.5rem;
  }
  .p-paginator-pages {
    width: 80vw;
    text-align: center;
  }
}

.input-error {
  color: #fca5a5;
  font-size: 14px;
}

/* Custom CSS for my-orders section */
.my-orders .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: unset;
  font-size: 0.875rem;
}
.my-orders .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: unset;
  font-size: 0.875rem;
}
.my-orders .p-tabview .p-tabview-panels {
  background-color: unset;
  padding: 0;
  margin-top: 1rem;
  color: unset;
}

.orders-button-sm {
  @include styleclass("text-sm text-black bg-gray-300 border-gray-300 flex justify-content-center align-items-center lg:h-2rem");
}
.orders-button-lg {
  @include styleclass("text-sm text-black bg-gray-300 border-gray-300 flex justify-content-center align-items-center xl:h-2rem");
}
/* End Custom CSS for my-orders section */

// Toast CSS
.p-toast .p-toast-message .p-toast-message-content {
  gap: 1rem;
}
.p-toast .p-toast-message {
  margin-bottom: 0.5rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -10px;
  margin-left: -10px;
}

.dialog-icon-btn {
  .p-button.p-button-icon-only {
    width: 2rem;
    padding: 0.75rem 0;
  }

  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2rem;
  }

  .p-button.p-button-rounded {
    border-radius: 2rem;
  }
}
